<template>
  <div class="about">
    <div class="header">
      <h1>关于我们</h1>
    </div>
    <div class="center box1">
      <div class="container">
        <div class="item1" ref="content_left">
          <div class="textBox animate__animated animate__fadeInUp">关于我们</div>
          <h1 class="animate__animated animate__fadeInUp animate__delay-.6s">行业领先者</h1>
          <p class="animate__animated animate__fadeInUp animate__delay-.7s animate__slow">
            兰州博燃燃气科技有限公司是一家专业从事燃气设备集成、安装、软件研发、系统运行维护一体化解决方案的高科技企业。公司自成立以来，瞄准燃气自动化控制领域，针对燃气行业组建专业性的研发团队，致力于燃气行业的自动化系统研究。我公司系昆仑能源总部《安全监控系统建设技术规定》编委之一。
          </p>
          <button class="animate__animated animate__fadeInLeftBig animate__delay-.9s">查看更多</button>
        </div>
        <div class="item2 animate__animated animate__fadeIn" >
          <div class="textBox animate__animated animate__fadeInRightBig right_div">
            <h1>主要经营业务</h1>
            <p>
              主要经营：天然气站场自动化控制系统(SCADA)的研发、销售、安装、调试;SCADA燃气输配监控系统及设备、各类应用软件;是集燃气输配工艺现场仪器仪表控制，数据监控、采集，视频监控为一体的自动化控制系统集成商。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="center box2">
      <div class="container">
        <div class="item1" ref="fadeInUp1">
          <h4 class="animate__animated animate__delay-.9s">优质高效 合作共赢</h4>
          <h1 class="animate__animated animate__delay-.9s">共同创造美好未来</h1>
        </div>
        <div class="item2" ref="fadeInUp2">
          <button class="animate__animated animate__delay-.9s">查看更多</button>
        </div>
      </div>
    </div>
    <div class="center box3">
      <div class="container">
        <button class="animate__animated animate__delay-.9s" ref="fadeInUp3">OUR TEAM</button>
        <h1 class="animate__animated animate__delay-.9s" ref="fadeInUp4">我们的团队</h1>
        <div class="teamBox">
          <div class="btnBox">
            <el-button type="text" ><i class="el-icon-arrow-left" @click="moveMethod1('left')"></i></el-button>
          </div>
          <div class="center">
              <ul class="moveBox" ref="moveRef">
                <li v-for="(item,index) in roleList">
                  <div>
                    <img style="background-color:#fff;" :src="item.imgSrc" />
                    <h3>{{item.name}}</h3>
                    <h5>{{item.position}}</h5>
                  </div>
                </li>
              </ul>
          </div>
          <div class="btnBox">
            <el-button type="text" ><i class="el-icon-arrow-right" @click="moveMethod1('right')"></i></el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="center box4">
      <div class="item1"></div>
      <div class="item2">
        <div class="container" ref="fadeInUp5">
          <h1 class="animate__animated animate__delay-1s">行业标杆企业</h1>
          <span class="animate__animated animate__delay-1s"></span>
          <p class="animate__animated animate__delay-1s">
            兰州博燃燃气科技有限公司是昆仑燃气、西南管道公司、华润集团、北京燃气等燃气集团的合格供应商。燃气SCADA生产运营系统，甘肃省占有率90%以上，云南省占有率50%以上。燃气公司用户超过100家以上。
          </p>
          <div class="showBox animate__animated animate__delay-1s">
            <div v-for="item in imgList">
              <img :src="item.src" />
              <h3>{{item.title}}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center box5">
      <div class="container">
        <button ref="fadeInUp6" class="animate__animated animate__delay-1s">SERVICE ARES</button>
        <h1 ref="fadeInUp7" class="animate__animated animate__delay-1s">我们的服务网络</h1>
        <img src="../../assets/image/aboutMap.webp" />
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "About",
  data(){
    return{
      imgList:[
        {
          src:require('../../assets/image/aboutIcon1.webp'),
          title:'最佳材料'
        },
        {
          src:require('../../assets/image/aboutIcon2.webp'),
          title:'最新设计'
        },
        {
          src:require('../../assets/image/aboutIcon3.webp'),
          title:'数字机械'
        },
        {
          src:require('../../assets/image/aboutIcon4.webp'),
          title:'安全施工'
        },
      ],
      roleList:[
        {
          imgSrc:require('../../assets/image/aboutRole.png'),
          name:'Dawei Wang / 王大伟',
          position:'高级工程师'
        },
        {
          imgSrc:require('../../assets/image/aboutRole.png'),
          name:'Dawei Wang / 王大伟',
          position:'高级工程师'
        },
        {
          imgSrc:require('../../assets/image/aboutRole.png'),
          name:'Dawei Wang / 王大伟',
          position:'高级工程师'
        },
        {
          imgSrc:require('../../assets/image/aboutRole.png'),
          name:'Dawei Wang / 王大伟',
          position:'高级工程师'
        },
        {
          imgSrc:require('../../assets/image/aboutRole.png'),
          name:'Dawei Wang / 王大伟',
          position:'高级工程师'
        },
      ],
      timer:null,
      number:0,
      scroll:0,
      negative:false,
    }
  },
  mounted() {
    this.moveMethod()
    window.addEventListener("scroll",this.menu)
  },
  methods:{
    moveMethod(){
      let that = this
      if(this.$refs.moveRef){
        let widthOnly = (this.$refs.moveRef.offsetWidth)/this.roleList.length
        let moveNumber = (this.$refs.moveRef.offsetWidth-1209)/403
        clearInterval(this.timer)
        this.timer = setInterval(function (){
          if(!that.navigator){
            //正向移动
            if(that.number<=moveNumber){
              that.$refs.moveRef.style.left = (-widthOnly*that.number)+'px'
              that.number++
            }else{
              that.navigator=true
              that.number--
              that.$refs.moveRef.style.left = (-widthOnly*that.number)+'px'
            }
          }else{
            if(0<that.number){
              that.number--
              that.$refs.moveRef.style.left = (-widthOnly*that.number)+'px'
            }else{
              that.navigator=false
              that.number++
              that.$refs.moveRef.style.left = (-widthOnly*that.number)+'px'

            }
          }
        },2000)
      }

    },
    moveMethod1(type){
      clearInterval(this.timer)
      let widthOnly = (this.$refs.moveRef.offsetWidth)/this.roleList.length
      let moveNumber = (this.$refs.moveRef.offsetWidth-1209)/403
      if(type === 'left'){
        let position = parseInt(this.$refs.moveRef.style.left)
        if(position<0){
          this.$refs.moveRef.style.left = position+403+'px'
          this.number=Math.abs((position+403)/403)
        }
      }
      if(type === 'right'){
        let position = -(widthOnly*moveNumber)
        let nowPosition = parseInt(this.$refs.moveRef.style.left)
        if(position<=nowPosition){
          this.$refs.moveRef.style.left = nowPosition-403+'px'
          this.number=Math.abs((nowPosition-403)/403)
        }
      }
      this.moveMethod()
    },
    //滚动条监听与设置
    menu() {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
      this.goScrollTop()
    },
    //设置
    goScrollTop() {
      console.log(this.scroll)
      if(this.scroll >= 200) {
        this.$refs['fadeInUp1'].childNodes.forEach((n,i)=>{
           n.classList.add('animate__fadeInUp')
        })
        this.$refs['fadeInUp2'].childNodes.forEach((n,i)=>{
          n.classList.add('animate__fadeInUp')
        })
      }
      if(this.scroll>=350){
        this.$refs['fadeInUp3'].classList.add('animate__fadeInUp')
        this.$refs['fadeInUp4'].classList.add('animate__fadeInUp')
      }
      if(this.scroll>=1300){
        this.$refs['fadeInUp5'].childNodes.forEach((n,i)=>{
          n.classList.add('animate__fadeInUp')
        })
      }
      if(this.scroll>=1800){
        this.$refs['fadeInUp6'].classList.add('animate__fadeInUp')
        this.$refs['fadeInUp7'].classList.add('animate__fadeInUp')
      }
    },
  }
}
</script>

<style lang="less" scoped>
.header{
  position: relative;
  text-align: center;
  width: 100%;
  height: 302px;
  background: url("../../assets/image/aboutHeader.webp") no-repeat center center;
  background-size: cover;
  h1{
    position: absolute;
    bottom: 10px;
    width: 62.5%;
    margin-left: 18.75%;
    text-align: left;
    font-weight: 300;
    font-size: 50px;
    color: #ffffff;
  }
}
.center{
  width: 100%;
}
.box1{
  display: flex;
  justify-content: center;
  align-items: center;
  height:575px;
  text-align: center;
  background-color: #fff;
  width: 100%;
  .container{
    display: flex;
    height: 341px;
    width: 62.5%;
    .item1{
      width: 33.33%;
      height: 100%;
      text-align: left;
      .textBox{
        width: 93px;
        height: 28px;
        background-color:black;
        line-height: 28px;
        color: white;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
      }
      h1{
        font-size: 30px;
        color: #000000;
        font-weight: 400;
        text-align: left;
        margin: 30px 0;
      }
      p{
        font-size: 14px;
        color: #001541;
        text-align: left;
        line-height: 1.5em;
        letter-spacing: 1px;
        width: 370px;
        margin-bottom: 30px;
      }
      button{
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background-color: #FF5C23;
        padding: 10px 35px;
        border: 2px #FF5C23;
      }
    }
    .item2{
      position: relative;
      width: 66.66%;
      height: 100%;
      background: url("../../assets/image/about1.webp") no-repeat left bottom;
      background-size:auto auto ;
      .textBox{
        position: absolute;
        width: 300px;
        height: 150px;
        padding:20px 40px 40px;
        background-color: #FF5C23;
        right: 0;
        bottom: 20px;
        h1{
          font-size: 26px;
          font-weight: 400;
          text-align: left;
          color: #ffffff;
          margin: 0 0 15px;
        }
        p{
          font-size: 14px;
          color: #ffffff;
          text-align: left;
          line-height: 1.5em;
          letter-spacing: 1px;
          width: 100%;
        }
      }
    }
  }
}
.box2{
  width: 100%;
  height: 189px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/image/about2.webp") no-repeat;
  background-size: cover;
  .container{
    display: flex;
    justify-content: space-between;
    width: 62.5%;
    height: 89px;
    .item1{
      h4{
        font-size: 14px;
        font-weight: 100;
        color: #FF5C23;
        text-align: left;
        margin: 0;
      }
      h1{
        color: #ffffff;
        margin: 6px 0 0;
        font-weight: 400;
        font-size: 40px;
      }
    }
    .item2{
      button{
        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
        background-color: #FF5C23;
        padding: 10px 35px;
        border: 2px #FF5C23;
        margin-top: 28px;
      }
    }
  }
}
.box3{
  width: 100%;
  height: 805px;
  background-color: #ffffff;
  .container{
    button{
      color: #ffffff;
      box-sizing: border-box;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      font-weight: 700;
      background-color: #000000;
      padding: 3px 10px;
      border: 0;
      margin-top: 10vh;
    }
    h1{
      font-size: 30px;
      color: #000000;
      font-weight: 400;
      margin: 10px 0 30px;
    }
    .teamBox{
      display: flex;
      width: calc(62.5% + 100px);
      height: 488px;
      line-height: 488px;
      margin: 20px auto 0;
      .btnBox{
        width: 50px;
        height: 100%;
        .el-button{
          font-size: 35px;
          font-weight: 500;
          padding: 0;
          background-color: transparent;
          color: #001541;
          &:hover{
            color: #FF5C23;
          }
        }
      }
      .center{
        overflow-x: hidden;
        height: 100%;
        ul{
          display: flex;
          position: relative;
          left: 0;
          transition: all 1s ease;
          margin: 0;
          padding: 0;
          width: max-content;
          li{
            flex-grow: inherit;
            box-sizing: border-box;
            width: 403px;
            height: 488px;
            margin: 0;
            padding: 30px;
            div{
              display: flex;
              flex-direction: column;
              //background-color: green;
              width: 100%;
              height: 100%;
              border: 1px solid rgba(226,226,226);
              &:hover{
                background-color: #FF5C23;
                color: #ffffff;
              }
              img{
                width: 100%;
                height: 341px;
              }
              h3{
                height: 36px;
                line-height: 36px;
                font-weight: 400;
                font-size: 16px;
                margin: 16px 0 0;
              }
              h5{
                height: 26px;
                line-height: 26px;
                font-size: 13px;
                margin: 0;
              }
            }

          }
        }
      }
    }
  }
}
.box4{
  display: flex;
  width: 100%;
  height: 642px;
  background-color: green;
  .item1{
    width: 50%;
    height: 100%;
    background: url("../../assets/image/about3.webp") no-repeat center center;
    background-size: cover;
  }
  .item2{
    width: 50%;
    background-color: #FF5C23;
    display: flex;
    align-items: center;//垂直居中
    padding-left: 5em;
    .container{
      width: 472px;
      height: 365px;
      h1{
        font-size: 36px;
        color: #ffffff;
        text-align: left;
        font-weight: 400;
      }
      span{
        display: block;
        width: 50px;
        height: 3px;
        background-color: #fff;
        margin: 30px 238px 35px 0;
      }
      p{
        font-size: 14px;
        color: #ffffff;
        text-align: left;
        line-height: 1.5em;
        letter-spacing: 1px;
        width: 100%;
      }
      .showBox{
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        div{
          width: 236px !important;
          display: flex;
          height: 49px;
          line-height: 49px;
          margin-bottom: 15px;
          img{
            width: 40px;
            height: 40px;
          }
          h3{
            font-size: 20px;
            font-weight: 400;
            color: #ffffff;
            margin: 0 0 0 15px;
          }
        }
      }
    }
  }
}
.box5{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 814px;
  background-color: #ffffff;
  .container{
    width: 1190px;
    height: 627px;
    button{
      color: #ffffff;
      box-sizing: border-box;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      font-weight: 700;
      background-color: #000000;
      padding: 3px 10px;
      border: 0;
    }
    h1{
      font-size: 30px;
      color: #000000;
      font-weight: 400;
      margin: 10px 0 30px;
    }
  }
}
</style>