<template>
  <div class="AboutDetails">
    <div class="header">
      <h1>产品</h1>
    </div>
    <div class="container">
      <div class="menuBox">
        <h1>产品项目</h1>
        <div v-for="(item,index) in menuList" :style="[index===checkedIndex? activeClass :'']" @click="changeCheck(index)">
          &ensp;>&ensp;{{item.title}}
        </div>
      </div>
      <div class="detailsBox">
        <h1>{{checkedProduct.title}}</h1>
        <img v-for="item in checkedProduct.imgSrc" :src="require(`../../assets/productDetails/${item}`)" :key="item" />
        <div v-for="(item,index) in checkedProduct.paragraphList" :key="index">
          <h3>{{item.title}}</h3>
          <p v-for="items in item.text">{{items}}</p>
          <div v-for="items in item.children">
            <h4>{{items.title}}</h4>
            <p>{{ items.text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import productData from '../../assets/data/productDetails.json'

export default {
  name: "AboutDetails",
  data(){
    return{
      menuList:[
        {
          title:'远程有（无）线抄表系统',
        },
        {
          title:'燃气场站安防系统',
        },
        {
          title:'燃气报警系统',
        },
        {
          title:'燃气智能巡检系统',
        },
        {
          title:'燃气管网地理信息GIS系统',
        },
        {
          title:'燃气RTU控制系统',
        },
        {
          title:'燃气SCADA系统',
        },
      ],
      checkedProduct:{
        title:'',
        imgSrc:[],
        paragraphList:[]
      },
      checkedIndex:0,
      activeClass:{
        backgroundColor: '#FF5C23',
        color: '#ffffff'
      }
    }
  },
  beforeRouteEnter(to,from,next){
    next(vm => {
      if(to.name==='AboutDetails'){
        if(to.params.index!==undefined||to.params.index!==null){
          vm.checkedIndex=to.params.index
          vm.checkedProduct = productData[vm.checkedIndex]
        }else{
          vm.checkedIndex=0
          vm.checkedProduct = productData[vm.checkedIndex]
        }
      }else{
        vm.checkedIndex=0
        vm.checkedProduct = productData[vm.checkedIndex]
      }
    })
  },
  mounted() {
    document.body.scrollTop = document.documentElement.scrollTop=0
  },
  methods:{
    changeCheck(index){
      this.checkedIndex=index
      this.checkedProduct = productData[index]
    }
  }
}
</script>

<style lang="less" scoped>
.header{
  position: relative;
  text-align: center;
  width: 100%;
  height: 302px;
  background: url("../../assets/image/productHeader.webp") no-repeat center center;
  background-size: cover;
  h1{
    position: absolute;
    bottom: 10px;
    width: 62.5%;
    margin-left: 18.75%;
    text-align: left;
    font-weight: 300;
    font-size: 50px;
    color: #ffffff;
  }
}
.container{
  width: 62.5%;
  margin: 0 auto;
  //background-color: pink;
  height: auto;
  display: flex;
  padding: 70px 0 80px;
  .menuBox{
    width: 230px;
    h1{
      font-size: 20px;
      font-weight: 600;
      margin: 0 0 15px;
      text-align: left;
    }
    div{
      text-align: left;
      width: 200px;
      height: 54px;
      line-height: 54px;
      background-color: #EDF5F8;
      font-size: 14px;
      color: #001514;
      margin-bottom: 1px;
      cursor: pointer;
      &:hover{
        background-color: #FF5C23;
        color: #ffffff;
      }
    }
  }
  .detailsBox{
    height: auto;
    width: 950px;
    margin-left: 20px;
    //background-color: green;
    h1{
      color: #101010;
      font-weight: 500;
      font-size: 40px;
      margin: 0 0 20px;
    }
    img{
      max-width: 100%;
    }
    div{
      text-align: left;
      p{
        text-indent: 33px;
      }
    }
  }
}

</style>