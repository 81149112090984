<template>
  <commonCase :infos="infos" />
</template>

<script>
import commonCase from "@/components/commonCase";
export default {
  name: "Wuwei",
  components:{
    commonCase
  },
  data() {
    return {
      infos:{
        title:'武威CNG加气母站',
        time:'发布时间:2018-03-26 14:18:26 | 浏览次数：308',
        lititle:'2017年，武威CNG加气母站自动系统和视频监控系统建设。',
        imgInfo:[
          {
            name:'控制柜图',
            url:require('../assets/image_demo/wuwei1.jpg')
          },
          {
            name:'阀门图',
            url:require('../assets/image_demo/wuwei2.jpg')
          },
          {
            name:'控制室对现场的监控',
            url:require('../assets/image_demo/wuwei3.jpg')
          },
          {
            name:'防爆区域专用防爆摄像机',
            url:require('../assets/image_demo/wuwei4.jpg')
          },
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>