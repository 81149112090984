<template>
  <commonCase :infos="infos" />
</template>

<script>
import commonCase from "@/components/commonCase";
export default {
  name: "Gangu",
  components:{
    commonCase
  },
  data() {
    return {
      infos:{
        title:'甘谷门站和母站合建站',
        time:'发布时间:2018-03-30 16:43:19 | 浏览次数：11464',
        lititle:'2016年，甘谷县城市门站和加气母站合建站。',
        imgInfo:[
          {
            name:'控制室',
            url:require('../assets/image_demo/gangu1.jpg')
          },
          {
            name:'控制柜和配电柜',
            url:require('../assets/image_demo/gangu2.jpg')
          },
          {
            name:'控制室操作界区域',
            url:require('../assets/image_demo/gangu3.jpg')
          },
          {
            name:'现场视频监控',
            url:require('../assets/image_demo/gangu4.jpg')
          },
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>