<template>
  <div id="homePage">
    <div class="scorllImg">
      <el-carousel height="795px" indicator-position="none">
        <el-carousel-item v-for="item in imgArr" :key="item">
          <img :src="require('../../assets/image_demo/' + item)" />
        </el-carousel-item>
      </el-carousel>

      <div class="divPosition">
        <div style="width: 100%;text-align: left">
          <h1 class="animate__animated animate__fadeInUp" style="font-size: 40px">燃气信息化集成监控系统</h1>
          <h2 class="animate__animated animate__fadeInUp">全心全意为您提供高品质服务</h2>
        </div>
      </div>

      <div class="container">
        <div class="imgs_div" v-for="item in imgIcons" :key="item">
          <div class="every_div">
            <img :src="require('../../assets/image_demo/' + item)" />
            <div>170+</div>
            <div style="font-size: 18px">成功项目经验</div>
          </div>
        </div>
        <img  class="imgLine" src="../../assets/image_demo/line.jpg" />
      </div>
    </div>
    <div class="middleTop" style="width: 100%;height: 780px;background: #FFFFFF">
        <div class="middle_content">
            <div class="content">
                <div class="content_left" ref="content_left">
                    <div class="animate__animated">
                      <button style="background: black;color: #FFFFFF;border: none;padding: 5px 10px;">关于我们</button>
                    </div>
                  <h2 class="animate__animated  animate__delay-.9s" style="width: 100%;text-align: left">兰州博燃燃气科技有限公司</h2>
                  <div class="animate__animated  animate__delay-.9s animate__slow" style="text-align: left;letter-spacing: 2px">
                    兰州博燃燃气科技有限公司是一家专业从事燃气设备集成、安装、软件研发、系统运行维护一体化解决方案的高科技企业。公司自成立以来，瞄准燃气自动化控制领域，针对燃气行业组建专业性的研发团队，致力于燃气行业的自动化系统研究。 我公司系昆仑能源总部《安全监控系统建设技术规定》编委之一。 我公司主要经营：天然气站场自动化控制系统（SCADA）的研发、销售、安装、调试；SCADA燃气输配监控系统及设备、各类应用软件；是集燃气输配工艺现场仪器仪表控制，数据监控、采集，视频监控为一体的自动化控制系统集成商。 兰州博燃燃气科技有限公司是昆仑燃气、西南管道公司、华润集团、北京燃气等燃气集团的合格供应商。燃气SCADA生产运营系统，甘肃省占有率90%以上，云南省占有率50%以上。燃气公司用户超过100家以上。
                  </div>
                  <div class="animate__animated  animate__delay-1s">
                    <button @click="toAbout" style="background: #FF5C23;cursor: pointer;color: #FFFFFF;border: none;padding: 5px 10px;width: 167px;height: 40px;margin-top: 10px">查看更多</button>
                  </div>
                </div>
              <div class="content_right">
                  <img src="../../assets/image_demo/fhome1.png" />
                <div class="animate__animated animate__delay-0.8s right_div" ref="right_div">
                    <h2>中国制造2022</h2>
                  <div>
                    公司始终坚持以“质量第一，服务贯穿过程”的经营理念，真诚的为中国燃气事业的发展尽我们的责任与力量。
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
    <div class="middle_img">
      <div class="middle_div">
        <div class="animate__animated img_left_title" ref="title">
          <p style="color: #FF5C23;font-size: 15px">优质高效&nbsp;&nbsp;合作共赢</p>
          <h1>共同创造美好未来</h1>
        </div>
        <div class="animate__animated img_right_btn animate__delay-1s" ref="btn">
          <button @click="toProductCenter" style="cursor: pointer;background: #FF5C23;color: #FFFFFF;border: none;padding: 5px 10px;width: 167px;height: 40px;">查看产品</button>
        </div>
      </div>
    </div>
    <div class="large_div">
      <div class="div_top">
        <div style="width: 200px">PRODUCT CENTER</div>
        <h1>产品中心</h1>
      </div>
      <div class="div_middle">
        <div class="showBox" ref="showItem">
          <div @click="toProductCenter" :class="`animate__delay-${index%2}s`" class="showItem animate__animated" v-for="(item,index) in imgList" :key="index">
            <img :src="item.imgSrc" />
            <div class="itemBox">
              <img :src="item.iconSrc" />
              <div>
                <h4>{{item.title}}</h4>
                <p>{{item.text}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="img_widthfull">
      <div>
        <img style="width: 100%;height: 100%" src="../../assets/image_demo/video.jpg"/>
      </div>
      <div style="width: 100%;height: 100%;background: #FF5C23">
        <div class="title_div" ref="title_div">
          <div class="animate__animated" style="margin-top: 150px;font-size: 36px;font-weight: normal;font-style: normal;">青羊地区标杆企业</div>
          <div class="animate__animated" style="border-bottom:3px solid #FFFFFF;width: 3vw;margin: 35px 0"></div>
          <div class="animate__animated animate__delay-1s" style="font-size: 15px">
            兰州博燃燃气科技有限公司是昆仑燃气、西南管道公司、华润集团、北京燃气等燃气集团的合格供应商。燃气SCADA生产运营系统，甘肃省占有率90%以上，云南省占有率50%以上。燃气公司用户超过100家以上。
          </div>
          <div class="animate__animated animate__delay-1s" style="width: 505px;height: 100px;display: flex;flex-direction: column;flex-wrap: wrap;margin-top: 50px">
            <div style="height: 49px;width: 235px;display: flex;align-items: center" v-for="item in iconList" :key="item.title">
              <img style="width: 40px;height: 40px" :src="item.src"/>
              <div style="margin-left: 10px">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section_img">
      <div class="title_top animate__animated" ref="title_top">
        <div>
          <div style="color: #FFFFFF;background: #000000;width: 125px;height: 28px;text-align: center;line-height: 28px;font-size: 15px;font-weight: bold">CASE DISPLAY</div>
          <div style="font-size: 30px;text-align: left;border-right: 2px solid gray;">案列展示</div>
        </div>
        <div>
          甘谷门站和母站合建站、武威CNG加气母站、甘肃分公司生产调度指挥中心升级改造。
        </div>
      </div>
    </div>
    <div class="bigimg_scorll">
<!--      <div style="width: 114px;height: 28px;background: #FF5C23;color: #FFFFFF;text-align: center;line-height: 28px;margin:100px auto 0">OUR CLIENTS</div>-->
      <div style="color: #FFFFFF;font-weight: bold;font-size: 30px;letter-spacing: 2px;margin: 50px 0">成就客户，互利互赢</div>
      <el-carousel indicator-position="none" arrow="always">
        <el-carousel-item v-for="(item,index) in caseImg" :key="item">
          <img @click="toCase(index)" :src="item" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="footer_top">
      <div style="width: 114px;height: 28px;background: #000000;color: #FFFFFF;text-align: center;line-height: 28px;margin:100px auto 0;font-size: 15px">LATEST NEWS</div>
      <div style="font-weight: bold;font-size: 30px;letter-spacing: 2px;margin-top: 10px;">最新前沿信息</div>
      <div style="height: 28vh;margin-top: 55px;display: flex;justify-content: center" ref="news">
        <div class="animate__animated animate__fadeInUp animate__delay-2s" style="width: 38vh;height: 100%;border: 1px solid rgba(128,128,128,0.42);margin: 0 10px" v-for="(item,index) in news" :key="item.content">
          <div style="width:29vh;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;word-break: break-all;margin:55px auto 0">{{item.title}}</div>
          <el-divider></el-divider>
          <div style="width: 29vh;margin: auto;color: #ADADAD;font-size: 13px">
            {{ item.content }}
          </div>
          <div @click="toNews" class="iconfont icon-igw-l-direction-right-rectangle" style="color: #FF5C23;font-size: 28px;margin-top: 40px;cursor: pointer"></div>
        </div>
      </div>
    </div>
    <div class="footer_bottom">

    </div>
  </div>

</template>

<script>
export default {
  name: "HomePage",
  data() {
    return {
      scroll:'',
      imgArr:[
        'bigImg4.jpg',
        'bigImg2.jpg',
        'bigImg3.jpg',
      ],
      imgIcons:[
          'home-(6).webp',
          'home-(5).webp',
          'home-(3).webp',
          'home-(4).webp',
          'home-(2).webp'
      ],
      imgList:[
        {
          imgSrc:require('../../assets/image/product1.webp'),
          iconSrc:require('../../assets/image/productIcon1.webp'),
          title:'远程有（无）线抄表系统',
          text:'您可以在这里填写项目的相关信息您可以在这里填写项目的相关信息'
        },
        {
          imgSrc:require('../../assets/image/product2.webp'),
          iconSrc:require('../../assets/image/productIcon2.webp'),
          title:'燃气场站安防系统',
          text:'您可以在这里填写项目的相关信息您可以在这里填写项目的相关信息'
        },
        {
          imgSrc:require('../../assets/image/product3.webp'),
          iconSrc:require('../../assets/image/productIcon3.webp'),
          title:'燃气报警系统',
          text:'您可以在这里填写项目的相关信息您可以在这里填写项目的相关信息'
        },
        {
          imgSrc:require('../../assets/image/product4.webp'),
          iconSrc:require('../../assets/image/productIcon4.webp'),
          title:'燃气智能巡检系统',
          text:'您可以在这里填写项目的相关信息您可以在这里填写项目的相关信息'
        },
        {
          imgSrc:require('../../assets/image/product5.webp'),
          iconSrc:require('../../assets/image/productIcon5.webp'),
          title:'燃气管网地理信息GIS系统',
          text:'您可以在这里填写项目的相关信息您可以在这里填写项目的相关信息'
        },
        {
          imgSrc:require('../../assets/image/product6.webp'),
          iconSrc:require('../../assets/image/productIcon6.webp'),
          title:'燃气RTU控制系统',
          text:'您可以在这里填写项目的相关信息您可以在这里填写项目的相关信息'
        },
      ],
      iconList:[
        {
          src:require('../../assets/image/aboutIcon1.webp'),
          title:'最佳材料'
        },
        {
          src:require('../../assets/image/aboutIcon2.webp'),
          title:'最新设计'
        },
        {
          src:require('../../assets/image/aboutIcon3.webp'),
          title:'数字机械'
        },
        {
          src:require('../../assets/image/aboutIcon4.webp'),
          title:'安全施工'
        },
      ],
      news:[
        {
          title:'兰州博燃燃科技有限公司',
          content:'前阵子，公司一个人遇到个记者，记者问他："看您身板硬朗、精神矍铄，请问您养生秘诀是什么？"'
        },
        {
          title:'兰州博燃燃科技有限公司',
          content:`面试官：作为设计师，你有什么特长吗？
          求职者：我会一些手绘`
        },
        {
          title:'兰州博燃燃科技有限公司',
          content:'Q：快到下班时间了，公司准备聚餐的同事分成了两拨，一拨准备去吃烤肉，另一拨准备去吃火锅，此时，你应该怎么做？'
        },
      ],
      caseImg:[
        require('../../assets/image_demo/caseDisplay1.jpg'),
        require('../../assets/image_demo/caseDisplay2.jpg'),
        require('../../assets/image_demo/caseDisplay3.jpg'),
      ]
    }
  },
  methods: {
    //关于我们查看
    toAbout() {
      this.$router.push('/about');
    },
    //查看产品
    toProductCenter() {
      this.$router.push('/productCenter');
    },
    //案列展示
    toCase(index) {
      if(index === 0) this.$router.push('/gangu')
      else if(index === 1) this.$router.push('/wuwei')
      else this.$router.push('/gansu')
    },
    //新闻
    toNews() {
      this.$router.push('/companyNews')
    },
    //滚动条监听与设置
    menu() {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
      this.goScrollTop()
    },
    //设置
    goScrollTop() {
      let i = 0
      console.log(this.scroll)
      if(this.scroll >= 190) {
        this.$refs['content_left'].childNodes.forEach((n,i)=> n.classList.add('animate__fadeInUp'))
        this.$refs['right_div'].classList.add('animate__fadeInRightBig')
      }
      if(this.scroll >= 550) {
        const arr = ['title','btn']
        while (i < 2) {
          this.$refs[arr[i]].classList.add('animate__fadeInUp');
          i++;
        }
      }
      if(this.scroll >= 1180) {
        this.$refs['showItem'].childNodes.forEach((n,i)=> n.classList.add('animate__fadeInUp'))
      }
      if(this.scroll >= 2050) {
        this.$refs['title_div'].childNodes.forEach((n,i)=> n.classList.add('animate__fadeInUp'))
      }
      if(this.scroll >= 2560) {
        this.$refs['title_top'].classList.add('animate__fadeInUp');
      }
      if(this.scroll >= 3400) {
        this.$refs['news'].childNodes.forEach((n,i)=> n.classList.add('animate__fadeInUp'))
      }
    },
  },
  mounted() {
    this.$nextTick(()=>{
      window.addEventListener("scroll", this.menu);
      this.$once("hook:destroyed", () => {
        window.removeEventListener("scroll", this.menu, true);
      });
    })
  }
}
</script>

<style lang="less" scoped>
#homePage {
  width: 100%;
  .scorllImg {
    width: 100%;
    height: 795px;
    box-sizing: border-box;
    position: relative;
    img {
     width: 100%;
      height: 100%;
    }
    .divPosition {
      width: 40%;
      height: 43%;
      color: #FFFFFF;
      padding: 30px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      position: absolute;
      top: 30%;
      left: 27%;
      z-index: 2;
      /deep/ .el-button {
        border-radius: 0;
      }
    }
    .container {
      width: 60%;
      height: 28%;
      position: absolute;
      top: 93%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 2;
      box-sizing: border-box;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      background-image: url("../../assets/image_demo/backgroundimg.jpg");
      background-size: contain;
      .imgs_div {
        width: 20%;
        height: 100%;
        box-sizing: border-box;
        font-size: 2.5vw;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        .every_div {
          img {
            width: 5vw;
            height: 5vw;
          }
        }
      }
      .imgLine {
        width: 100%;
        height: 2.5vh;
        position: absolute;
        z-index: 4;
        bottom: -50px;
      }
    }
  }
  .middleTop {
    width: 100%;
    height: 85vh !important;
    position: relative;
    .middle_content {
      width: 99vw;
      height: 70vh;
      position: absolute;
      box-sizing: border-box;
      bottom: 0;
      background-image: url("../../assets/image_demo/contentImg.jpg");
      background-repeat: no-repeat;
      background-size: cover;
      .content {
        width: 60%;
        height: 100%;
        margin: auto;
        display: flex;
        align-items: center;

        .content_left {
          height: 40vh;
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-content: space-between;
          flex: 1.2;
          div {
            font-size: 14px;
          }
        }
        .content_right {
          height: 35vh;
          box-sizing: border-box;
          position: relative;
          margin-left: 50px;
          flex: 2;
          img {
            width: 25vw;
            height: 100%;
            position: absolute;
            left: 60px;
          }
          .right_div {
            width: 380px;
            height: 160px;
            position: absolute;
            background: #FF5C23;
            right: -30px;
            top: 25%;
            transform: translate(0%,-50%);
            color: #FFFFFF;
            box-sizing: border-box;
            text-align: left;
            padding: 0 20px;
            div {
              //font-weight: bold;
            }
          }
        }
      }
    }
  }
  .middle_img {
  width: 100%;
  height: 20vh;
  background-image: url("../../assets/image_demo/bgcImg.jpg") ;
  background-repeat: no-repeat;
  background-size: cover;
  .middle_div {
    width: 74vw;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    color: #FFFFFF;
    padding: 0 0.5vw;
    .img_left_title {
      text-align: left;
    }
  }
}
  .large_div {
    width: 99vw;
    height: 94vh;
    .div_top {
      width: 100%;
      height: 20vh;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      div {
        width: 7vw;
        background: #000000;
        padding: 5px 3px;
        font-size: .8vw;
        margin: 0 auto;
        color: #FFFFFF;
        font-weight: 600;
      }
    }
    .div_middle{
      display: flex;
      justify-content: center;
      .showBox{
        width: 62vw;
        margin: 20px 0 80px;
        display: flex;
        flex-wrap: wrap;
        .showItem{
          cursor: pointer;
          width: 377px;
          height: 314px;
          margin-top: 40px;
          margin-right: 29px;
          img{
            width: 100%;
            height: 205px;
          }
          .itemBox{
            display: flex;
            margin-top: 20px;
            img{
              width: 60px;
              height: 60px;
              margin-right: 34px;
            }
            div{
              text-align: left;
              h4{
                font-size: 20px;
                color: #101010;
                font-weight: 600;
                margin: 0 0 10px;
              }
              p{
                line-height: 1.5em;
                font-size: 14px;
                letter-spacing: 1px;
              }
            }
          }
        }
        .showItem:nth-child(3n){
          margin-right: 0;
        }
      }
    }
  }
  .img_widthfull {
    width: 100%;
    height: 65vh;
    box-sizing: border-box;
    display: flex;
    margin-top: 35px;
    div {
      flex: 1;
      .title_div {
        width: 25vw;
        height: 100%;
        text-align: left;
        color: #FFFFFF;
        margin-left: 5vw;
      }
    }
  }
  .section_img {
    width: 100%;
    height: 18vh;
    .title_top {
      width: 63%;
      height: 20vh;
      margin: auto;
      display: flex;
      &>div:nth-child(1) {
        flex: 1;
        box-sizing: border-box;
        margin-top: 50px;
      }
      &>div:nth-child(2) {
        text-align: left;
        flex: 2;
        display: flex;
        align-items: center;
        letter-spacing: 1px;
        font-size: 14px;
        word-wrap: break-word;
        box-sizing: border-box;
        padding: 0 20px;
      }
    }
    .scrill_img_bottom {
      height: 58vh;
      width: 33%;
      margin: auto;
      .el-carousel__item img {
        width: 100%;
      }
    }
  }
  .bigimg_scorll {
    width: 100%;
    height: 57vh;
    overflow: auto;
    background-image: url("../../assets/image_demo/bigImg3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
      .el-carousel__item {
        width: 63%;
        height: 100%;
        margin: auto;
        left: 19%;
        color: #FFFFFF;
        img {
          width: 500px;
          cursor: pointer;
          //height: 403px;
        }
      }
  }
  .footer_top {
    width: 100%;
    height: 63vh;
    box-sizing: border-box;
    overflow: auto;
  }

}
@font-face {
  font-family: "iconfont"; /* Project id 3356183 */
  src: url('//at.alicdn.com/t/font_3356183_0nknklle614m.woff2?t=1652670442891') format('woff2'),
  url('//at.alicdn.com/t/font_3356183_0nknklle614m.woff?t=1652670442891') format('woff'),
  url('//at.alicdn.com/t/font_3356183_0nknklle614m.ttf?t=1652670442891') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-igw-l-direction-right-rectangle:before {
  content: "\e658";
}

</style>