<template>
  <div id="ProductCenter">
    <div class="header">
      <h1>公司新闻</h1>
    </div>
    <div class="center box1">
      <div class="container">
        <button class="animate__animated animate__fadeInLeft">OUR NEWS</button>
        <h1 class="animate__animated animate__fadeInLeft">公司新闻</h1>
        <div class="showBox">
         <div class="showBox_item" v-for="item in newsList" :key="item.content">
           <div>
             <span>{{item.content}}</span>
             <span>{{item.time}}</span>
           </div>
           <el-divider></el-divider>
         </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyNews",
  data() {
    return {
      newsList: [
        {
          time:'2022-05-17',
          content:'前阵子，公司一个人遇到个记者，记者问他："看您身板硬朗、精神矍铄，请问您养生秘诀是什么？"'
        },
        {
          time:'2022-05-17',
          content:`面试官：作为设计师，你有什么特长吗？
          求职者：我会一些手绘`
        },
        {
          time:'2022-05-17',
          content:'Q：快到下班时间了，公司准备聚餐的同事分成了两拨，一拨准备去吃烤肉，另一拨准备去吃火锅，此时，你应该怎么做？'
        },
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.header{
  position: relative;
  text-align: center;
  width: 100%;
  height: 302px;
  background: url("../../assets/image_demo/newsImg.jpg") no-repeat center center;
  background-size: cover;
  h1{
    position: absolute;
    bottom: 10px;
    width: 62.5%;
    margin-left: 18.75%;
    text-align: left;
    font-weight: 300;
    font-size: 50px;
    color: #ffffff;
  }
}
.center{
  width: 100%;
}
.box1{
  width: 100%;
  //height: 986px;
  height: auto;
  .container{
    width: 62.5%;
    margin: 0 auto;
    button{
      color: #ffffff;
      box-sizing: border-box;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      font-weight: 700;
      background-color: #000000;
      padding: 3px 10px;
      border: 0;
      margin-top: 10vh;
    }
    h1{
      font-size: 30px;
      color: #000000;
      font-weight: 400;
      margin: 10px 0 30px;
    }
    .showBox{
      width: 100%;
      margin: 20px 0 80px;
      display: flex;
      flex-wrap: wrap;
      .showBox_item {
        width: 100%;
       &>div {
         display: flex;
         justify-content: space-between;

       }
      }
    }
  }
}
</style>