<template>
  <commonCase :infos="infos" />
</template>

<script>
import commonCase from "@/components/commonCase";

export default {
  name: "Gansu",
  components:{
    commonCase
  },
  data() {
    return {
      infos:{
        title:'甘肃分公司生产调度指挥中心升级改造',
        time:'发布时间:2017-12-27 11:02:49 | 浏览次数：1527',
        lititle:'我公司2017年，历时半年，严格按照中石油昆仑能源总部组织编订的《安全监控系统建设技术规定》，完成了甘肃省下属的天水、张掖、武威、临夏、嘉峪关等13个门站、加气母站站控系统软件的升级改造工作。',
        imgInfo:[
          {
            name:'调度室',
            url:require('../assets/image_demo/gansu1.jpg')
          },
          {
            name:'',
            url:require('../assets/image_demo/gansu2.jpg')
          },
          {
            name:'总生产指挥系统',
            url:require('../assets/image_demo/gansu3.jpg')
          },
          {
            name:'',
            url:require('../assets/image_demo/gansu4.jpg')
          },
          {
            name:'对下游公司的站控系统数据监控',
            url:require('../assets/image_demo/gansu1.jpg')
          },
          {
            name:'对下游公司的远程视频监控',
            url:require('../assets/image_demo/gansu1.jpg')
          },
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>