<template>
<div id="caseDisplay">
  <div class="top">
    <img src="../../assets/image_demo/imgCaseDisplay.jpg"/>
    <div style="color: #FFFFFF;position: absolute;bottom: 45px;font-size: 50px;left: 20%;">项目案例</div>
  </div>
  <div class="bottom">
    <div class="bottom_top animate__animated animate__fadeInLeft">OUR PROJECTS</div>
    <div class="bottom_title animate__animated animate__fadeInLeft">经典项目展示</div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane style="display: flex;justify-content: center" label="案例展示" name="first">
        <div @click="jump(item.name)" class="exhibition animate__animated  animate__fadeIn animate__delay-1s" v-for="item in caseDisplayImg" :key="item.title">
          <img :src="item.src"/>
          <div class="bgc">
            <div class="bgc_border"></div>
            <div class="title_div">{{item.title}}</div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</div>
</template>

<script>
export default {
  name: "CaseDisplay",
  data() {
    return {
      activeName: 'first',
      caseDisplayImg:[
        {
          name:'Gangu',
          src:require('../../assets/image_demo/caseDisplay1.jpg'),
          title:'甘谷门站和母站合建站'
        },
        {
          name:'Wuwei',
          src:require('../../assets/image_demo/caseDisplay2.jpg'),
          title:'武威CNG加气母站'
        },
        {
          name:'Gansu',
          src:require('../../assets/image_demo/caseDisplay3.jpg'),
          title:'甘肃分公司生产调度指挥中心升级改造'
        },
      ]
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    jump(name) {
      this.$router.push({
        name,params:{}
      })
    }
  }
}
</script>

<style lang="less" scoped>
#caseDisplay {
  width: 100%;
  .top {
    width: 100%;
    height: 32vh;
    position: relative;
    top: 0px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bottom {
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 80px 0;
    .bottom_top {
      width: 130px;
      height: 28px;
      background: #000000;
      color: #FFFFFF;
      text-align: center;
      line-height: 28px;
      font-weight: bold;
      font-size: 14px;
      margin:50px auto 0;
    }
    .bottom_title {
      font-size: 35px;
      margin: 8px 0;
    }
    .exhibition {
      width: 270px;
      height: 220px;
      margin: 10px;
      position: relative;
      img {
        width: 100%;
        height: 220px;
      }
      .title_div {
        margin: 0;
        font-weight: 600;
        //text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        font-size: 16px;
        opacity: 0;
        transition: all .35s linear;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
      .bgc {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        .bgc_border {
          width: 110%;
          height: 110%;
          border: 1px solid #FFFFFF;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          transition: all .35s linear;
        }
      }
    }
    .exhibition:hover .bgc {
      color: #FFFFFF;
      font-size: 15px;
      background: rgba(255, 92, 35, 0.66);
      cursor: pointer;
    }
    .exhibition:hover .bgc .bgc_border {
      width: 90%;
      height: 90%;
    }
    .exhibition:hover .title_div {
      opacity: 1;
    }
    .exhibition:hover .bgc::after {


    }
  }
}
/deep/ .el-tabs__nav-scroll {
  display: flex;
}
/deep/ .el-tabs__nav {
  margin: auto;
}
</style>