<template>
  <div id="caseDisplay">
    <div class="top">
      <img src="../../assets/image_demo/imgCaseDisplay.jpg"/>
      <div style="color: #FFFFFF;position: absolute;bottom: 75px;font-size: 50px;left: 20%;">联系我们</div>
    </div>
    <div class="bottom animate__animated  animate__fadeIn">
      <div style="flex: 1" v-for="item in imgSrc" :key="item.src">
        <el-image
            :src="item.src"
            :preview-src-list="srcList">
        </el-image>
      </div>
    </div>
    <div style="height: 20vh">

    </div>
  </div>
</template>

<script>
export default {
  name: "ContactInformation",
  data() {
    return {
      imgSrc:[
        {
          src:require('../../assets/image_demo/address1.png')
        },
        {
          src:require('../../assets/image_demo/address2.png')
        },
      ],
      srcList: [
        require('../../assets/image_demo/address1.png'),
        require('../../assets/image_demo/address2.png')
      ]
    }
  },
}
</script>

<style lang="less" scoped>
#caseDisplay {
  width: 100%;
  .top {
    width: 100%;
    height: 32vh;
    position: relative;
    top: 0px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bottom {
    width: 80%;
    height: 45vh;
    position: relative;
    left: 50%;
    top: 80px;
    transform: translateX(-50%);
    //border: 1px solid rgba(136, 136, 136, 0.46);
    overflow: auto;
    box-sizing: border-box;
    padding: 80px 0;
    //box-shadow: 0px 0px 5px rgba(136, 136, 136, 0.46);
    display: flex;
    div {
      width: ;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

}
</style>