<template>
  <div id="caseDisplay">
    <div class="top">
      <img src="../assets/image_demo/imgCaseDisplay.jpg"/>
      <div style="color: #FFFFFF;position: absolute;bottom: 45px;font-size: 50px;left: 20%;">项目案例</div>
    </div>
    <div class="bottom">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane style="width: 27vw;margin: auto" label="案例展示" name="first">
          <h3>{{ infos.title }}</h3>
          <hr/>
          <div>{{infos.time}}</div>
          <hr/>
          <div style="font-size: 14px">{{infos.lititle}}</div>
          <div style="margin-top: 30px" v-for="item in infos.imgInfo">
            <img :src="item.url"/>
            <div style="margin-top: 10px">{{item.name}}</div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: "commonCase",
  props:{
    infos:{
      type:Object,
      default: {},
    }
  },
  data() {
    return {
      activeName: 'first',
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
  }
}
</script>

<style lang="less" scoped>
#caseDisplay {
  width: 100%;
  .top {
    width: 100%;
    height: 32vh;
    position: relative;
    top: 0px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .bottom {
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 80px 0;
  }

}
/deep/ .el-tabs__nav-scroll {
  display: flex;
}
/deep/ .el-tabs__nav {
  margin: auto;
}
</style>