<template>
  <div id="ProductCenter">
    <div class="header">
      <h1>产品</h1>
    </div>
    <div class="center box1">
      <div class="container">
        <button>OUR PRODUCTS</button>
        <h1>我们的产品</h1>
        <div class="showBox">
          <div class="showItem" @click="goTo(index)" v-for="(item,index) in imgList" :key="index">
            <img :src="item.imgSrc" />
            <div class="itemBox">
              <img :src="item.iconSrc" />
              <div>
                <h4>{{item.title}}</h4>
                <p>{{item.text}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductCenter",
  data(){
    return{
      imgList:[
        {
          imgSrc:require('../../assets/image/product1.webp'),
          iconSrc:require('../../assets/image/productIcon1.webp'),
          title:'远程有（无）线抄表系统',
          text:'您可以在这里填写项目的相关信息您可以在这里填写项目的相关信息'
        },
        {
          imgSrc:require('../../assets/image/product2.webp'),
          iconSrc:require('../../assets/image/productIcon2.webp'),
          title:'燃气场站安防系统',
          text:'您可以在这里填写项目的相关信息您可以在这里填写项目的相关信息'
        },
        {
          imgSrc:require('../../assets/image/product3.webp'),
          iconSrc:require('../../assets/image/productIcon3.webp'),
          title:'燃气报警系统',
          text:'您可以在这里填写项目的相关信息您可以在这里填写项目的相关信息'
        },
        {
          imgSrc:require('../../assets/image/product4.webp'),
          iconSrc:require('../../assets/image/productIcon4.webp'),
          title:'燃气智能巡检系统',
          text:'您可以在这里填写项目的相关信息您可以在这里填写项目的相关信息'
        },
        {
          imgSrc:require('../../assets/image/product5.webp'),
          iconSrc:require('../../assets/image/productIcon5.webp'),
          title:'燃气管网地理信息GIS系统',
          text:'您可以在这里填写项目的相关信息您可以在这里填写项目的相关信息'
        },
        {
          imgSrc:require('../../assets/image/product6.webp'),
          iconSrc:require('../../assets/image/productIcon6.webp'),
          title:'燃气RTU控制系统',
          text:'您可以在这里填写项目的相关信息您可以在这里填写项目的相关信息'
        },
        {
          imgSrc:require('../../assets/image/product6.webp'),
          iconSrc:require('../../assets/image/aboutIcon2.webp'),
          title:'燃气SCADA系统',
          text:'您可以在这里填写项目的相关信息您可以在这里填写项目的相关信息'
        },
      ]
    }
  },
  methods:{
    goTo(index){
      this.$router.push(
          {name:'AboutDetails', params:{index:index}})
    }
  }
}
</script>

<style lang="less" scoped>
.header{
  position: relative;
  text-align: center;
  width: 100%;
  height: 302px;
  background: url("../../assets/image/productHeader.webp") no-repeat center center;
  background-size: cover;
  h1{
    position: absolute;
    bottom: 10px;
    width: 62.5%;
    margin-left: 18.75%;
    text-align: left;
    font-weight: 300;
    font-size: 50px;
    color: #ffffff;
  }
}
.center{
  width: 100%;
}
.box1{
  width: 100%;
  //height: 986px;
  height: auto;
  .container{
    width: 62.5%;
    margin: 0 auto;
    button{
      color: #ffffff;
      box-sizing: border-box;
      height: 28px;
      line-height: 28px;
      font-size: 14px;
      font-weight: 700;
      background-color: #000000;
      padding: 3px 10px;
      border: 0;
      margin-top: 10vh;
    }
    h1{
      font-size: 30px;
      color: #000000;
      font-weight: 400;
      margin: 10px 0 30px;
    }
    .showBox{
      width: 100%;
      margin: 20px 0 80px;
      display: flex;
      flex-wrap: wrap;
      .showItem{
        cursor: pointer;
        width: 377px;
        height: 314px;
        margin-top: 40px;
        margin-right: 29px;
        img{
          width: 100%;
          height: 205px;
        }
        .itemBox{
          display: flex;
          margin-top: 20px;
          img{
            width: 60px;
            height: 60px;
            margin-right: 34px;
          }
          div{
            text-align: left;
            h4{
              font-size: 20px;
              color: #101010;
              font-weight: 600;
              margin: 0 0 10px;
            }
            p{
              line-height: 1.5em;
              font-size: 14px;
              letter-spacing: 1px;
            }
          }
        }
      }
      .showItem:nth-child(3n){
        margin-right: 0;
      }
    }
  }
}
</style>